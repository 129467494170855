<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="shadow-sm border-0 rounded-lg"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title position-sticky d-flex justify-content-between align-items-center mb-4">
            <h1 class="font-weight-bolder mb-0">
                {{ isEditing ? `Update client: ${ name }` : "Create client" }}
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>

        <form v-on:submit.prevent="save"
              v-on:reset.prevent="reset">
            <div class="mb-5">
                <h4 class="subheading position-sticky font-weight-light pb-2 mb-2">
                    Client Settings
                </h4>

                <div class="form-row">
                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                Category <small class="text-danger">Required</small>
                            </template>
                            <b-form-select class="bg-light"
                                           required
                                           placeholder=""
                                           v-model="category">
                                <template v-slot:first>
                                    <b-form-select-option v-bind:value="null"
                                                          disabled>
                                        Please select a category
                                    </b-form-select-option>
                                </template>
                                <b-form-select-option v-for="category in categories"
                                                      v-bind:key="category.id"
                                                      v-bind:value="category">
                                    {{ category.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                Badges Awarded
                            </template>
                            <b-form-checkbox-group v-model="badges">
                                <p class="mb-0"
                                   v-if="badgeOptions.length === 0">
                                    --- No badge assignable yet ---
                                </p>
                                <b-form-checkbox v-for="badge in badgeOptions"
                                                 v-bind:key="badge.id"
                                                 v-bind:value="badge">
                                    🏵️ {{ badge.name }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>

                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                Point Scheme Enrolled
                            </template>
                            <b-form-select class="bg-light"
                                           placeholder=""
                                           v-model="pointScheme">
                                <template v-slot:first>
                                    <b-form-select-option v-bind:value="null"
                                                          disabled>
                                        Please select a point scheme
                                    </b-form-select-option>
                                </template>
                                <b-form-select-option v-for="scheme in pointSchemes"
                                                      v-bind:key="scheme.id"
                                                      v-bind:value="scheme">
                                    {{ scheme.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-12"
                         v-if="pointScheme">
                        <b-form-group>
                            <template v-slot:label>
                                Point Balance
                            </template>
                            <b-form-spinbutton min="0" step="1"
                                               v-model="pointBalance">
                            </b-form-spinbutton>
                        </b-form-group>
                    </div>
                </div>
            </div>

            <div class="mb-5">
                <h4 class="subheading font-weight-light pb-2 mb-2">
                    Contact information
                </h4>

                <div class="form-row">
                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                Contact Name <small class="text-danger">Required</small>
                            </template>
                            <b-form-input class="bg-light"
                                          type="text"
                                          required
                                          v-model="name">
                            </b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                Account Number
                            </template>
                            <b-form-input class="bg-light"
                                          type="text"
                                          v-model="accountNo">
                            </b-form-input>
                        </b-form-group>
                    </div>


                    <div class="col-12 mb-4">
                        <b-form-group>
                            <template v-slot:label>
                                Primary Person
                            </template>
                            <b-input-group>
                                <b-form-input class="bg-light col-8"
                                              type="text"
                                              placeholder="First Name"
                                              v-model="firstName">
                                </b-form-input>
                                <b-form-input class="bg-light"
                                              type="text"
                                              placeholder="Last Name"
                                              v-model="lastName">
                                </b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>

                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                Email <small class="text-danger">Required</small>
                            </template>
                            <b-form-input class="bg-light"
                                          type="email"
                                          required
                                          v-model="email">
                            </b-form-input>
                        </b-form-group>
                    </div>

                    <b-form-group class="col-12">
                        <template v-slot:label>
                            Phone
                        </template>
                        <b-input-group>
                            <b-form-input class="bg-light"
                                          style="flex: 1; "
                                          type="tel"
                                          placeholder="Country"
                                          v-model="phoneCountry">
                            </b-form-input>
                            <b-form-input class="bg-light"
                                          style="flex: 1; "
                                          type="tel"
                                          placeholder="District"
                                          v-model="phoneArea">
                            </b-form-input>
                            <b-form-input class="bg-light col-8"
                                          style="flex: 3; "
                                          type="tel"
                                          placeholder="Number"
                                          v-model="phoneNumber">
                            </b-form-input>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group class="col-12">
                        <template v-slot:label>
                            Fax
                        </template>
                        <b-input-group>
                            <b-form-input class="bg-light"
                                          style="flex: 1; "
                                          type="tel"
                                          placeholder="Country"
                                          v-model="faxCountry">
                            </b-form-input>
                            <b-form-input class="bg-light"
                                          style="flex: 1; "
                                          type="tel"
                                          placeholder="District"
                                          v-model="faxArea">
                            </b-form-input>
                            <b-form-input class="bg-light col-8"
                                          style="flex: 3; "
                                          type="tel"
                                          placeholder="Number"
                                          v-model="faxNumber">
                            </b-form-input>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group class="col-12">
                        <template v-slot:label>
                            Mobile
                        </template>
                        <b-input-group>
                            <b-form-input class="bg-light"
                                          style="flex: 1; "
                                          type="tel"
                                          placeholder="Country"
                                          v-model="mobileCountry">
                            </b-form-input>
                            <b-form-input class="bg-light"
                                          style="flex: 1; "
                                          type="tel"
                                          placeholder="District"
                                          v-model="mobileArea">
                            </b-form-input>
                            <b-form-input class="bg-light col-8"
                                          style="flex: 3; "
                                          type="tel"
                                          placeholder="Number"
                                          v-model="mobileNumber">
                            </b-form-input>
                        </b-input-group>
                    </b-form-group>

                    <div class="col-12 mb-4">
                        <b-form-group>
                            <template v-slot:label>
                                Website
                            </template>
                            <b-form-input class="bg-light"
                                          type="text"
                                          v-model="website">
                            </b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 mb-3">
                        <b-form-group>
                            <template v-slot:label>
                                Postal Address
                            </template>
                            <b-form-input class="bg-light"
                                          type="text"
                                          placeholder="Attention"
                                          v-model="postal.attention">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <b-form-textarea class="bg-light"
                                             v-model="postal.address">
                            </b-form-textarea>
                        </b-form-group>
                        <b-form-group>
                            <b-form-input class="bg-light"
                                          type="text"
                                          placeholder="City/Town"
                                          v-model="postal.city">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input-group>
                                <b-form-input class="bg-light col-8"
                                              style="flex: 3; "
                                              type="text"
                                              placeholder="State/Region"
                                              v-model="postal.region">
                                </b-form-input>
                                <b-form-input class="bg-light"
                                              style="flex: 1; "
                                              type="text"
                                              placeholder="Postal/Zip code"
                                              v-model="postal.postalCode">
                                </b-form-input>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group>
                            <b-form-input class="bg-light"
                                          type="text"
                                          placeholder="Country"
                                          v-model="postal.country">
                            </b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12">
                        <b-form-group>
                            <template v-slot:label>
                                <div class="d-flex align-items-center">
                                    <span class="mr-2">Street Address</span>
                                    <button class="btn btn-sm btn-link py-0"
                                            v-on:click.prevent.stop="street = Object.assign({}, postal)">
                                        Same as postal address
                                    </button>
                                </div>

                            </template>
                            <b-form-input class="bg-light"
                                          type="text"
                                          placeholder="Attention"
                                          v-model="street.attention">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <b-form-textarea class="bg-light"
                                             v-model="street.address">
                            </b-form-textarea>
                        </b-form-group>
                        <b-form-group>
                            <b-form-input class="bg-light"
                                          type="text"
                                          placeholder="City/Town"
                                          v-model="street.city">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input-group>
                                <b-form-input class="bg-light col-8"
                                              style="flex: 3; "
                                              type="text"
                                              placeholder="State/Region"
                                              v-model="street.region">
                                </b-form-input>
                                <b-form-input class="bg-light"
                                              style="flex: 1; "
                                              type="text"
                                              placeholder="Postal/Zip code"
                                              v-model="street.postalCode">
                                </b-form-input>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group>
                            <b-form-input class="bg-light"
                                          type="text"
                                          placeholder="Country"
                                          v-model="street.country">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

            </div>

            <div class="mb-5">
                <h4 class="subheading font-weight-light pb-2 mb-2">
                    Sales information
                </h4>

                <b-form-group>
                    <template v-slot:label>
                        Business Registration
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="bizReg">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="mb-5">
                <h4 class="subheading font-weight-light pb-2 mb-2">
                    Social Media
                </h4>

                <b-form-group label-cols="2">
                    <template v-slot:label>
                        Facebook
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="facebook">
                    </b-form-input>
                </b-form-group>
                <b-form-group label-cols="2">
                    <template v-slot:label>
                        Twitter
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="twitter">
                    </b-form-input>
                </b-form-group>
                <b-form-group label-cols="2">
                    <template v-slot:label>
                        Instagram
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="instagram">
                    </b-form-input>
                </b-form-group>
                <b-form-group label-cols="2">
                    <template v-slot:label>
                        WhatsApp
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="whatsapp">
                    </b-form-input>
                </b-form-group>
                <b-form-group label-cols="2">
                    <template v-slot:label>
                        Signal
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="signal">
                    </b-form-input>
                </b-form-group>
                <b-form-group label-cols="2">
                    <template v-slot:label>
                        Telegram
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="telegram">
                    </b-form-input>
                </b-form-group>
                <b-form-group label-cols="2">
                    <template v-slot:label>
                        WeChat
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  v-model="wechat">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="mb-5">
                <h4 class="subheading font-weight-light pb-2 mb-2">
                    Others
                </h4>

                <b-form-group>
                    <template v-slot:label>
                        Tags
                    </template>
                    <b-form-tags class="bg-light"
                                 remove-on-delete
                                 v-model="tags">
                    </b-form-tags>
                </b-form-group>

                <b-form-group>
                    <template v-slot:label>
                        Interests
                    </template>
                    <b-form-tags class="bg-light"
                                 remove-on-delete
                                 placeholder="Add interest..."
                                 v-model="interests">
                    </b-form-tags>
                </b-form-group>

                <b-form-group>
                    <template v-slot:label>
                        Needs
                    </template>
                    <b-form-checkbox-group stacked
                                           v-model="needs">
                        <p class="mb-0"
                           v-if="needOptions.length === 0">
                            --- No need selectable yet ---
                        </p>
                        <b-form-checkbox v-for="need in needOptions"
                                         v-bind:key="need.id"
                                         v-bind:value="need">
                            {{ need.name }}
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                    <button class="btn btn-sm btn-link pl-0"
                            v-on:click.prevent.stop="isModalNeedOpen = true">
                        Add a new need
                    </button>
                </b-form-group>

                <b-form-group>
                    <template v-slot:label>
                        Strategies
                    </template>
                    <b-form-textarea class="bg-light"
                                     v-model="strategies">
                    </b-form-textarea>
                </b-form-group>

                <b-form-group>
                    <template v-slot:label>
                        Notes
                    </template>
                    <b-form-textarea class="bg-light"
                                     v-model="notes">
                    </b-form-textarea>
                </b-form-group>
            </div>

            <div class="position-sticky actions col-12 text-right py-1">
                <button class="btn btn-sm btn-secondary px-3 mr-2"
                        type="reset"
                        v-if="!isEditing && !client">
                    ♻️ Reset
                </button>
                <button class="btn btn-sm btn-primary px-3"
                        type="submit">
                    💾 Save
                </button>
            </div>
        </form>

        <modal-need v-model="isModalNeedOpen"></modal-need>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput, BFormTextarea,
    BInputGroup,
    BFormTags,
    BFormCheckboxGroup, BFormCheckbox,
    BFormSelect, BFormSelectOption,
    BFormSpinbutton,
} from "bootstrap-vue";
import ModalNeed from "./ModalClient/ModalNeed";

export default {
    name: "ModalClient",
    components: {
        ModalNeed,
        BModal,
        BFormGroup,
        BFormInput, BFormTextarea,
        BInputGroup,
        BFormTags,
        BFormCheckboxGroup, BFormCheckbox,
        BFormSelect, BFormSelectOption,
        BFormSpinbutton,
    },
    props: {
        isEditing: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        client: {
            type: Object,
        },
    },
    data () {
        return {
            isModalNeedOpen: false,

            category: null,
            badges: [],
            pointScheme: null,
            pointBalance: 0,

            name: "",
            accountNo: "",
            firstName: "",
            lastName: "",
            email: "",

            phoneCountry: "",
            phoneArea: "",
            phoneNumber: "",
            faxCountry: "",
            faxArea: "",
            faxNumber: "",
            mobileCountry: "",
            mobileArea: "",
            mobileNumber: "",
            website: "",

            postal: {
                attention: "",
                address: "",
                city: "",
                region: "",
                country: "",
            },
            street: {
                attention: "",
                address: "",
                city: "",
                region: "",
                country: "",
            },

            bizReg: "",

            facebook: "",
            instagram: "",
            twitter: "",
            whatsapp: "",
            signal: "",
            telegram: "",
            wechat: "",

            tags: [],
            interests: [],
            needs: [],
            strategies: "",
            notes: "",
        };
    },
    computed: {
        user () {
            return this.$store.getters["user/info"];
        },
        categories () {
            return this.$store.getters["data/categories"];
        },
        badgeOptions () {
            return this.$store.getters["data/badges"];
        },
        pointSchemes () {
            return this.$store.getters["data/pointSchemes"];
        },
        needOptions () {
            return this.$store.getters["data/needs"];
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.category = null;
            this.badges = [];
            this.pointScheme = null;
            this.pointBalance = 0;

            this.name = "";
            this.accountNo = "";
            this.firstName = "";
            this.lastName = "";
            this.email = "";

            this.phoneCountry = "";
            this.phoneArea = "";
            this.phoneNumber = "";
            this.faxCountry = "";
            this.faxArea = "";
            this.faxNumber = "";
            this.mobileCountry = "";
            this.mobileArea = "";
            this.mobileNumber = "";
            this.website = "";

            this.postal = {
                attention: "",
                address: "",
                city: "",
                region: "",
                country: "",
            };
            this.street = {
                attention: "",
                address: "",
                city: "",
                region: "",
                country: "",
            };

            this.bizReg = "";

            this.facebook = "";
            this.instagram = "";
            this.twitter = "";
            this.whatsapp = "";
            this.signal = "";
            this.telegram = "";
            this.wechat = "";

            this.tags = [];
            this.interests = [];
            this.needs = [];
            this.strategies = "";
            this.notes = "";
        },
        save () {
            if (this.isEditing === true) {
                this.edit();
                return;
            }
            this.add();
        },
        async add () {
            const {
                category, badges, pointScheme, pointBalance,
                name, accountNo,
                firstName, lastName,
                email,
                phoneCountry, phoneArea, phoneNumber,
                faxCountry, faxArea, faxNumber,
                mobileCountry, mobileArea, mobileNumber,
                website,
                postal, street,
                bizReg,
                facebook, instagram, twitter, whatsapp, signal, telegram, wechat,
                tags, interests, needs, strategies, notes,
            } = this;
            this.$store.commit(
                "data/addClient",
                {
                    category, badges, pointScheme, pointBalance,
                    name, accountNo,
                    firstName, lastName,
                    email,
                    phoneCountry, phoneArea, phoneNumber,
                    faxCountry, faxArea, faxNumber,
                    mobileCountry, mobileArea, mobileNumber,
                    website,
                    postal, street,
                    bizReg,
                    facebook, instagram, twitter, whatsapp, signal, telegram, wechat,
                    tags, interests, needs, strategies, notes,
                }
            );
            this.reset();
            this.hideModal();
        },
        async edit () {
            const {
                category, badges, pointScheme, pointBalance,
                name, accountNo,
                firstName, lastName,
                email,
                phoneCountry, phoneArea, phoneNumber,
                faxCountry, faxArea, faxNumber,
                mobileCountry, mobileArea, mobileNumber,
                website,
                postal, street,
                bizReg,
                facebook, instagram, twitter, whatsapp, signal, telegram, wechat,
                tags, interests, needs, strategies, notes,
            } = this;
            this.$store.commit(
                "data/editClient",
                {
                    "id": this.client.id,
                    category, badges, pointScheme, pointBalance,
                    name, accountNo,
                    firstName, lastName,
                    email,
                    phoneCountry, phoneArea, phoneNumber,
                    faxCountry, faxArea, faxNumber,
                    mobileCountry, mobileArea, mobileNumber,
                    website,
                    postal, street,
                    bizReg,
                    facebook, instagram, twitter, whatsapp, signal, telegram, wechat,
                    tags, interests, needs, strategies, notes,
                }
            );
            this.hideModal();
        },
    },
    mounted () {
        if (this.client) {
            this.category = this.client?.category ?? null;
            this.badges = this.client?.badges ?? [];
            this.pointScheme = this.client?.pointScheme ?? null;
            this.pointBalance = this.client?.pointBalance ?? 0;


            this.name = this.client.name;
            this.accountNo = this.client.accountNo;
            this.firstName = this.client.firstName;
            this.lastName = this.client.lastName;
            this.email = this.client.email;

            this.phoneCountry = this.client.phoneCountry;
            this.phoneArea = this.client.phoneArea;
            this.phoneNumber = this.client.phoneNumber;

            this.faxCountry = this.client.faxCountry;
            this.faxArea = this.client.faxArea;
            this.faxNumber = this.client.faxNumber;

            this.mobileCountry = this.client.mobileCountry;
            this.mobileArea = this.client.mobileArea;
            this.mobileNumber = this.client.mobileNumber;

            this.website = this.client.website;

            this.postal = this.client.postal;
            this.street = this.client.street;

            this.bizReg = this.client?.bizReg ?? "";

            this.facebook = this.client?.facebook ?? "";
            this.instagram = this.client?.instagram ?? "";
            this.twitter = this.client?.twitter ?? "";
            this.whatsapp = this.client?.whatsapp ?? "";
            this.signal = this.client?.signal ?? "";
            this.telegram = this.client?.telegram ?? "";
            this.wechat = this.client?.wechat ?? "";

            this.tags = this.client?.tags ?? [];
            this.interests = this.client?.interests ?? [];
            this.needs = this.client?.needs ?? [];
            this.strategies = this.client?.strategies ?? "";
            this.notes = this.client?.notes ?? "";
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}

.subheading {
    top: 51px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}

.actions {
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}
</style>
