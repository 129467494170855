<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow border-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <h1 class="font-weight-bolder mb-4">
            Create clients' need
        </h1>
        <form v-on:submit.prevent="create">
            <b-form-group>
                <template v-slot:label>
                    Name of Need
                </template>
                <b-form-input class="bg-light"
                              required
                              placeholder="Give it a Name📛"
                              v-model="name">
                </b-form-input>
            </b-form-group>

            <div class="text-right">
                <button class="btn btn-sm btn-primary px-3"
                        type="submit">
                    Confirm
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput,
} from "bootstrap-vue";

export default {
    name: "ModalNeed",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            name: "",
        };
    },
    computed: {

    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.name = "";
        },
        async create () {
            const { name, } = this;
            await this.$store.commit(
                "data/addNeed",
                { name, }
            );
            this.hideModal();
            this.reset();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
